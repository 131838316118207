// extracted by mini-css-extract-plugin
export var column = "PopularResources__column__l0Kme";
export var container = "PopularResources__container__VUiyr";
export var flex = "PopularResources__flex__DjI0u";
export var flexColumn = "PopularResources__flexColumn__bXg1n";
export var gap1 = "PopularResources__gap1__lqtgr";
export var gap2 = "PopularResources__gap2__q6nrt";
export var gap3 = "PopularResources__gap3__v5BHe";
export var gap4 = "PopularResources__gap4__HWQYe";
export var gap5 = "PopularResources__gap5__tQEmg";
export var resourceItemTitle = "PopularResources__resourceItemTitle__WGLoP";
export var row = "PopularResources__row__cbmDp";
export var seperator = "PopularResources__seperator__lV67v";
export var title = "PopularResources__title__DKgVv";