// extracted by mini-css-extract-plugin
export var categoryDescription = "CategoryResources__categoryDescription__kdLbs";
export var categoryItemContainer = "CategoryResources__categoryItemContainer__DgSgJ";
export var categoryTitle = "CategoryResources__categoryTitle__ZKdCT";
export var column = "CategoryResources__column__F4Jbd";
export var downloadButtonContainer = "CategoryResources__downloadButtonContainer__Fv57C";
export var flex = "CategoryResources__flex__JqKZ0";
export var flexColumn = "CategoryResources__flexColumn__QLYne";
export var gap1 = "CategoryResources__gap1__O3VD3";
export var gap2 = "CategoryResources__gap2__HiXuZ";
export var gap3 = "CategoryResources__gap3___hLsk";
export var gap4 = "CategoryResources__gap4__zkbRB";
export var gap5 = "CategoryResources__gap5__P6Cv2";
export var itemDescription = "CategoryResources__itemDescription__WRBJ1";
export var itemDetailContainer = "CategoryResources__itemDetailContainer__XP5s0";
export var itemHeading = "CategoryResources__itemHeading__U8vT7";
export var itemImageContainer = "CategoryResources__itemImageContainer__AxsI4";
export var paginationContainer = "CategoryResources__paginationContainer__huTcg";
export var resourceImage = "CategoryResources__resourceImage__OoWPv";
export var row = "CategoryResources__row__y8br3";